<template>
  <b-modal
    id="modal-order-update"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="EDIT ORDER"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickEditOrder)"
        >
          <b-row>
            <b-col cols="4" />
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Customer Name"
                label-for="h-name"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.customer_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the Customer Name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Customer Address"
                label-for="h-addressLine1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine1"
                  rules="required"
                >
                  <b-form-input
                    id="addressLine1"
                    v-model="form.customer_address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Customer Mobile"
                label-for="Mobile"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required"
                >
                  <b-form-input
                    id="Mobile"
                    v-model="form.customer_phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the Customer Mobile"
                    type="number"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="orderSettings.second_phone_number"
              cols="6"
            >
              <b-form-group
                label="Second Phone No*"
                label-for="secondPhoneNumber"
              >
                <b-form-input
                  id="secondPhoneNumber"
                  v-model="form.customer_secondary_phone"
                  type="number"
                  placeholder="0xxxxxxxxx"
                  autocomplete="nope"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Customer Email"
                label-for="h-email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.customer_email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter the Email Address"
                    type="email"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Pickup Address"
                label-for="pickupAddress"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="pickupAddress"
                  rules="required"
                >
                  <v-select
                    v-model="pickupAddress"
                    :options="businessAddresses"
                    :reduce="option => option.id"
                    label="pickup_address"
                    autocomplete="nope"
                    placeholder="Select Pickup Address"
                    @input="setDefaultOriginCity()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Destination City"
                label-for="h-city"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <v-select
                    v-model="form.destination_city_id"
                    :options="cityOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select the city"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Origin City"
                label-for="h-city"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.origin_city_id"
                    :hidden="true"
                  />
                  <b-form-input
                    v-model="form.origin_city_name"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Weight"
                label-for="h-weight"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="COD"
                  rules="required"
                >
                  <b-form-input
                    id="weight"
                    ref="weightInput"
                    v-model="form.weight"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    placeholder="1"
                    autocomplete="nope"
                    step=".01"
                    pattern="^\d*(\.\d{0,2})?$"
                    @input="checkWeightRange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="COD"
                label-for="h-cod"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="COD"
                  rules="required"
                >
                  <b-form-input
                    id="cod"
                    v-model="form.cod"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the COD"
                    type="number"
                    maxlength="5"
                    oninput="this.value=this.value.slice(0,this.maxLength)"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row />

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const orderRepository = RepositoryFactory.get('order')
const MerchantRepository = RepositoryFactory.get('merchant')
const OrderSettingsRepository = RepositoryFactory.get('orderSetting')

function initialState() {
  return {
    open: false,
    id: null,
    waybill: null,
    form: {
      order_no: '',
      customer_name: '',
      customer_address: '',
      customer_phone: '',
      customer_secondary_phone: '',
      customer_email: '',
      destination_city_id: '',
      cod: '',
      weight: '',
      description: '',
      remark: '',
    },
    modalLoading: false,
    loading: false,
    orderSettings: {},
    cityOptions: [],
    stateOptions: [],
    countryOptions: [],
    search: '',
    pickupAddress: '',
    concat: true,
    businessAddresses: [],
    defaultPickAddressId: '',
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async openModal(data, waybill) {
      const { id } = data
      Object.assign(this.$data, initialState())
      this.open = true
      this.modalLoading = true
      this.id = id
      this.waybill = waybill
      await this.fetchCityList()
      this.getAddress(data)
      this.modalLoading = false
      this.form = data
      this.pickupAddress = data.pickup_address != null ? data.pickup_address : ''
      await this.readOrderSetting()
    },
    async fetchOrderDetails() {
      try {
        const { data } = (await orderRepository.getOrderDetailsById(this.id)).data
        this.form = data
        this.pickupAddress = data.pickup_address != null ? data.pickup_address : ''
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readOrderSetting() {
      this.loading = true
      try {
        this.orderSettings = localStorage.getItem('order_setting') ? JSON.parse(localStorage.getItem('order_setting')) : (await OrderSettingsRepository.getOrderSettingsResource()).data.data
        if (!localStorage.getItem('order_setting')) localStorage.setItem('order_setting', JSON.stringify(this.orderSettings))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    checkWeightRange() {
      const minWeight = this.orderSettings.weight_range_setting.min_weight
      const maxWeight = this.orderSettings.weight_range_setting.max_weight
      const currentWeight = parseFloat(this.form.weight)
      if (this.orderSettings.weight_range_setting.enabled === true && (currentWeight < minWeight || currentWeight > maxWeight)) {
        this.validationState = false
        this.validationMessage = `The order weight should be between ${minWeight} Kg to ${maxWeight} Kg`
        this.isMaxWeight = true
      } else {
        this.validationState = true
        this.validationMessage = ''
        this.isMaxWeight = false
      }
      this.$refs.weightInput.setCustomValidity(this.validationMessage)
    },
    async fetchCityList() {
      // this.form.city = null
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownListNoFilter(this.search)).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getAddress(data) {
      this.loading = true
      try {
        this.pickupAddress = null
        this.businessAddresses = (await MerchantRepository.getbusinessAddresses(data.merchant_business.id, this.concat)).data.data
        localStorage.setItem('address', JSON.stringify(this.businessAddresses))
        if (data.pickup_address_id != null) {
          this.pickupAddress = data.pickup_address_id
        } else {
          this.defaultPickAddressId = (this.businessAddresses.filter(address => address.is_default))[0].id
          if (this.defaultPickAddressId) {
            this.pickupAddress = this.defaultPickAddressId
          }
        }
        await this.setDefaultOriginCity()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async setDefaultOriginCity() {
      try {
        this.loading = true
        const cities = JSON.parse(localStorage.getItem('address'))
        // Loop through the array and check for a matching city id
        let cityId = null
        let cityName = null
        cities.forEach(city => {
          if (city.id === this.pickupAddress) {
            cityId = city.city_id
            cityName = city.city_name
          }
        })
        if (cityId) {
          this.form.origin_city_id = cityId
          this.form.origin_city_name = cityName
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async onClickEditOrder() {
      this.modalLoading = true
      try {
        // eslint-disable-next-line no-unused-vars
        const { data } = (await MerchantRepository.getMerchantBusinessList(this.merchant_id)).data
        const payload = {
          general_data: {
            merchant_business_id: data[0].id,
            origin_city_id: this.form.origin_city_id,
            pickup_address_id: this.pickupAddress,
          },
          order_data: {
            waybill_number: this.waybill,
            customer_name: this.form.customer_name,
            customer_address: this.form.customer_address,
            customer_phone: this.form.customer_phone,
            customer_secondary_phone: this.form.customer_secondary_phone,
            customer_email: this.form.customer_email,
            destination_city_id: this.form.destination_city_id,
            cod: this.form.cod,
            weight: Number(this.form.weight),
          },
        }

        const res = await orderRepository.editDraftOrder(payload)

        if (res.status === 200) {
          this.showSuccessMessage('Successfully Edited')
        } else {
          this.showErrorMessage('Cannot Edit the Order')
        }
        this.$parent.onClickRefresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.open = false
      this.modalLoading = false
    },
  },
}
</script>
  <style scoped>
  @import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
  @import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
  @import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
  @import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
  @import '../../@core/scss/button-color.scss';
  </style>>
