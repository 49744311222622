var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-order-update","size":"lg","no-close-on-backdrop":"","hide-footer":"","title":"EDIT ORDER"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('b-overlay',{attrs:{"show":_vm.modalLoading,"blur":"10px","opacity":"0.30","rounded":"sm","variant":"light"}},[_c('ValidationObserver',{ref:"createMerchantForm",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"my-8",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onClickEditOrder)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Customer Name","label-for":"h-name"}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Enter the Customer Name","type":"text","autocomplete":"nope"},model:{value:(_vm.form.customer_name),callback:function ($$v) {_vm.$set(_vm.form, "customer_name", $$v)},expression:"form.customer_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Customer Address","label-for":"h-addressLine1"}},[_c('ValidationProvider',{attrs:{"name":"addressLine1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"addressLine1","state":errors.length > 0 ? false:null,"placeholder":"Enter the address","type":"text","autocomplete":"nope"},model:{value:(_vm.form.customer_address),callback:function ($$v) {_vm.$set(_vm.form, "customer_address", $$v)},expression:"form.customer_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Customer Mobile","label-for":"Mobile"}},[_c('ValidationProvider',{attrs:{"name":"Mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Mobile","state":errors.length > 0 ? false:null,"placeholder":"Enter the Customer Mobile","type":"number","autocomplete":"nope"},model:{value:(_vm.form.customer_phone),callback:function ($$v) {_vm.$set(_vm.form, "customer_phone", $$v)},expression:"form.customer_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.orderSettings.second_phone_number)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Second Phone No*","label-for":"secondPhoneNumber"}},[_c('b-form-input',{attrs:{"id":"secondPhoneNumber","type":"number","placeholder":"0xxxxxxxxx","autocomplete":"nope"},model:{value:(_vm.form.customer_secondary_phone),callback:function ($$v) {_vm.$set(_vm.form, "customer_secondary_phone", $$v)},expression:"form.customer_secondary_phone"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Customer Email","label-for":"h-email"}},[_c('ValidationProvider',{attrs:{"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":"Enter the Email Address","type":"email","autocomplete":"nope"},model:{value:(_vm.form.customer_email),callback:function ($$v) {_vm.$set(_vm.form, "customer_email", $$v)},expression:"form.customer_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Pickup Address","label-for":"pickupAddress"}},[_c('ValidationProvider',{attrs:{"name":"pickupAddress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.businessAddresses,"reduce":function (option) { return option.id; },"label":"pickup_address","autocomplete":"nope","placeholder":"Select Pickup Address"},on:{"input":function($event){return _vm.setDefaultOriginCity()}},model:{value:(_vm.pickupAddress),callback:function ($$v) {_vm.pickupAddress=$$v},expression:"pickupAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Destination City","label-for":"h-city"}},[_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.cityOptions,"reduce":function (option) { return option.id; },"label":"name","placeholder":"Select the city"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.form.destination_city_id),callback:function ($$v) {_vm.$set(_vm.form, "destination_city_id", $$v)},expression:"form.destination_city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Origin City","label-for":"h-city"}},[_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"hidden":true},model:{value:(_vm.form.origin_city_id),callback:function ($$v) {_vm.$set(_vm.form, "origin_city_id", $$v)},expression:"form.origin_city_id"}}),_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.form.origin_city_name),callback:function ($$v) {_vm.$set(_vm.form, "origin_city_name", $$v)},expression:"form.origin_city_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Weight","label-for":"h-weight"}},[_c('ValidationProvider',{attrs:{"name":"COD","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"weightInput",attrs:{"id":"weight","state":errors.length > 0 ? false:null,"type":"number","placeholder":"1","autocomplete":"nope","step":".01","pattern":"^\\d*(\\.\\d{0,2})?$"},on:{"input":_vm.checkWeightRange},model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", $$v)},expression:"form.weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"COD","label-for":"h-cod"}},[_c('ValidationProvider',{attrs:{"name":"COD","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cod","state":errors.length > 0 ? false:null,"placeholder":"Enter the COD","type":"number","maxlength":"5","oninput":"this.value=this.value.slice(0,this.maxLength)","autocomplete":"nope"},model:{value:(_vm.form.cod),callback:function ($$v) {_vm.$set(_vm.form, "cod", $$v)},expression:"form.cod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row'),_c('b-row',[_c('b-col',{attrs:{"offset-md":"4"}},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 custom-button-color",attrs:{"type":"submit"}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Clear ")])],1)])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }