<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="2"
          >
            <b-form-checkbox
              id="remarks"
              v-model="filters['order_remark']"
              :value="true"
              :unchecked-value="false"
            >
              Orders With Remarks
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="selection === 'allOrders' || selection === ''"
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Status"
              label-for="status"
            >
              <v-select
                v-model="statusFilters"
                :options="statusOptions"
                :reduce="option => option.key"
                label="name"
                placeholder="Select Status"
                multiple
                deselect-from-dropdown
              >
                <template
                  v-slot:option="option"
                  class="text-truncate"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Customer Name"
              label-for="customer_name"
            >
              <b-form-input
                id="customer_name"
                v-model="filters['customer_name']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Merchant Business"
              label-for="merchant business"
            >
              <v-select
                v-model="selectedBusinessFilters"
                :options="businesses"
                :reduce="option => option.id"
                label="business_name"
                placeholder="Select Merchant Business"
                multiple
                deselect-from-dropdown
              >
                <template v-slot:option="option">
                  {{ option.business_name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Phone Number"
              label-for="phone_no"
            >
              <b-form-input
                id="phone_no"
                v-model="filters['customer_phone']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Destination City"
              label-for="city"
            >
              <v-select
                v-model="filters['destination_city_id']"
                :options="cityOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select City"
                autocomplete="nope"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Status Changes Date"
              label-for="status_date"
            >
              <div class="position-relative">
                <flat-pickr
                  v-model="filters['status_date']"
                  class="form-control"
                  :config="{ mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                  placeholder="Search Date"
                />
                <div
                  v-if="filters['status_date']"
                  class="flatpickr-cancel"
                  @click="clearDate('status_date')"
                >
                  <feather-icon icon="XIcon" />
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Order Date"
              label-for="orders.created_at"
            >
              <div class="position-relative">
                <flat-pickr
                  v-model="filters['orders.created_at']"
                  class="form-control"
                  :config="{ mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                  placeholder="Search Date"
                />
                <div
                  v-if="filters['orders.created_at']"
                  class="flatpickr-cancel"
                  @click="clearDate('orders.created_at')"
                >
                  <feather-icon icon="XIcon" />
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Order Flags"
              label-for="flag"
            >
              <v-select
                v-model="selectedFlagFilters"
                :options="flagOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select Flags"
                multiple
                deselect-from-dropdown
              >
                <template
                  v-slot:option="option"
                  class="text-truncate"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Pickup Setting"
              label-for="pickup-setting"
            >
              <v-select
                v-model="filters['pickup_enable']"
                :options="pickupSettingOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select Setting"
                deselect-from-dropdown
              >
                <template
                  v-slot:option="option"
                  class="text-truncate"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="General Search"
              label-for="general_search"
            >
              <b-form-input
                id="general_search"
                v-model="filters['search']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <!-- <div class="my-orders-alert">
      <b-alert
        variant="danger"
        show
        class="d-flex align-items-center justify-content-center text-align-center small"
      >
        We are currently addressing a temporary disruption in our tile filter functionality.
      </b-alert>
    </div> -->
    <div class="mt-2">
      <b-row>
        <summary-card
          title="All ORDERS"
          :sum="orderOptions.all_orders.sum"
          :count="orderOptions.all_orders.count"
          :bg-variant="selection === 'allOrders'? '#4186f5' :''"
          :text-variant="selection === 'allOrders'? 'white' :'#5c5b61'"
          @click.native="onSummaryCardClick('','allOrders')"
        />
        <summary-card
          :title="statusOptions.filter(option => option.key === 'key_1')[0].name"
          :sum="orderOptions.draft.sum"
          :count="orderOptions.draft.count"
          :bg-variant="selection === 'draft'? '#4186f5' :''"
          :text-variant="selection === 'draft'? 'white' :'#5c5b61'"
          @click.native="onSummaryCardClick('key_1','draft')"
        />
        <summary-card
          :title="statusOptions.filter(option => option.key === 'key_2')[0].name"
          :sum="orderOptions.confirmed.sum"
          :count="orderOptions.confirmed.count"
          :bg-variant="selection === 'confirmed'? '#4186f5' :''"
          :text-variant="selection === 'confirmed'? 'white' :'#5c5b61'"
          @click.native="onSummaryCardClick('key_2','confirmed')"
        />
        <summary-card
          :title="statusOptions.filter(option => option.key === 'key_3')[0].name"
          :sum="orderOptions.cancelled.sum"
          :count="orderOptions.cancelled.count"
          :bg-variant="selection === 'cancelled'? '#4186f5' :''"
          :text-variant="selection === 'cancelled'? 'white' :'#5c5b61'"
          @click.native="onSummaryCardClick('key_3','cancelled')"
        />
        <summary-card
          :title="statusOptions.filter(option => option.key === 'key_13')[0].name"
          :sum="orderOptions.delivered.sum"
          :count="orderOptions.delivered.count"
          :bg-variant="selection === 'delivered'? '#4186f5' :''"
          :text-variant="selection === 'delivered'? 'white' :'#5c5b61'"
          @click.native="onSummaryCardClick('key_7,key_13','delivered')"
        />
        <summary-card
          :title="statusOptions.filter(option => option.key === 'key_14')[0].name"
          :sum="orderOptions.partially_delivered.sum"
          :count="orderOptions.partially_delivered.count"
          :bg-variant="selection === 'partially_delivered'? '#4186f5' :''"
          :text-variant="selection === 'partially_delivered'? 'white' :'#5c5b61'"
          @click.native="onSummaryCardClick('key_14','partially_delivered')"
        />
        <summary-card
          :title="statusOptions.filter(option => option.key === 'key_16')[0].name"
          :sum="orderOptions.rescheduled.sum"
          :count="orderOptions.rescheduled.count"
          :bg-variant="selection === 'rescheduled'? '#4186f5' :''"
          :text-variant="selection === 'rescheduled'? 'white' :'#5c5b61'"
          @click.native="onSummaryCardClick('key_16','rescheduled')"
        />
        <summary-card
          title="All RETURN ORDERS"
          :sum="orderOptions.failed.sum"
          :count="orderOptions.failed.count"
          :bg-variant="selection === 'failed'? '#4186f5' :''"
          :text-variant="selection === 'failed'? 'white' :'#5c5b61'"
          @click.native="onSummaryCardClick('key_20,key_21','failed')"
        />
      </b-row>

      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row>
              <b-col
                cols="12"
                xl="7"
                class="d-flex flex-column flex-xl-row align-items-start align-items-xl-center justify-content-start mb-1 mb-xl-0"
              >
                <b-form-group
                  label="Waybill Number"
                  label-for="waybill-number"
                  class="mobile-width-fix mr-xl-1"
                >
                  <b-form-input
                    id="waybill_number"
                    v-model="filters['waybill_number']"
                    placeholder="Search"
                  />
                </b-form-group>
                <b-form-group
                  label="Order Number"
                  label-for="order-number"
                  class="mobile-width-fix mr-xl-1"
                >
                  <b-form-input
                    id="order_number"
                    v-model="filters['order_no']"
                    placeholder="Search"
                  />
                </b-form-group>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="custom-button-color mobile-width-fix addNew"
                  @click="onClickCreate()"
                >
                  <feather-icon icon="PlusIcon" />
                  Add New
                </b-button>
              </b-col>
              <b-col
                cols="12"
                xl="5"
                class="d-flex flex-wrap justify-content-end align-items-xl-center mb-1 mb-md-0 mobile-width-change"
              >
                <div class="d-flex mobile-width-change">
                  <div class="d-flex mobile-width-change">
                    <b-button
                      v-if="selection === 'draft'"
                      variant="success"
                      class="buttonMargin mobile-width-fix mr-xl-1 mb-1 mb-md-0"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickBulkAction('key_2')"
                    >
                      Confirm
                    </b-button>
                    <b-button
                      v-if="selection === 'draft'"
                      variant="danger"
                      class="buttonMargin mobile-width-fix mb-1 mb-md-0 mr-xl-1"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onWarningDeletion()"
                    >
                      Discard
                    </b-button>
                    <b-button
                      v-if="selection === 'confirmed'"
                      variant="danger"
                      class="buttonMargin mr-1 mb-1 mb-md-0 mobile-width-fix"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickBulkAction('key_3')"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      v-if="selection === 'confirmed' && is_pickup_enable == true"
                      v-b-tooltip.hover.v-success="'Pickup Request'"
                      variant="success"
                      size="sm"
                      class="buttonMargin mr-1 mb-1 mb-md-0 mobile-width-fix"
                      :disabled="selectedWaybillArray.length === 0"
                      @click="onClickMakeBulkPickup()"
                    >
                      <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS10cnVjayI+PHBhdGggZD0iTTUgMThIM2MtLjYgMC0xLS40LTEtMVY3YzAtLjYuNC0xIDEtMWgxMGMuNiAwIDEgLjQgMSAxdjExIi8+PHBhdGggZD0iTTE0IDloNGw0IDR2NGMwIC42LS40IDEtMSAxaC0yIi8+PGNpcmNsZSBjeD0iNyIgY3k9IjE4IiByPSIyIi8+PHBhdGggZD0iTTE1IDE4SDkiLz48Y2lyY2xlIGN4PSIxNyIgY3k9IjE4IiByPSIyIi8+PC9zdmc+"
                      >
                    </b-button>
                    <b-dropdown
                      v-if="selection === 'confirmed'"
                      id="printDropdown"
                      v-b-tooltip.hover.v-warning="'Print Waybill'"
                      split-class="waybill-print-button-color-blur unable-pointer"
                      toggle-class="waybill-print-button-color"
                      split
                      size="sm"
                      role="menu"
                      class="buttonMargin mb-1 mb-md-0 mt-1 mobile-width-dropdown"
                      :disabled="selectedWaybillArray.length === 0"
                    >
                      <template #button-content>
                        <img
                          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1xci1jb2RlIj48cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiB4PSIzIiB5PSIzIiByeD0iMSIvPjxyZWN0IHdpZHRoPSI1IiBoZWlnaHQ9IjUiIHg9IjE2IiB5PSIzIiByeD0iMSIvPjxyZWN0IHdpZHRoPSI1IiBoZWlnaHQ9IjUiIHg9IjMiIHk9IjE2IiByeD0iMSIvPjxwYXRoIGQ9Ik0yMSAxNmgtM2EyIDIgMCAwIDAtMiAydjMiLz48cGF0aCBkPSJNMjEgMjF2LjAxIi8+PHBhdGggZD0iTTEyIDd2M2EyIDIgMCAwIDEtMiAySDciLz48cGF0aCBkPSJNMyAxMmguMDEiLz48cGF0aCBkPSJNMTIgM2guMDEiLz48cGF0aCBkPSJNMTIgMTZ2LjAxIi8+PHBhdGggZD0iTTE2IDEyaDEiLz48cGF0aCBkPSJNMjEgMTJ2LjAxIi8+PHBhdGggZD0iTTEyIDIxdi0xIi8+PC9zdmc+"
                        >
                      </template>
                      <b-dropdown-item
                        v-for="(option, index) in layoutDropdownOptions"
                        :key="index"
                        @click="onClickPrintDispatch(option.id)"
                      >
                        {{ option.name }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <b-col
                    md="12"
                    class="d-flex mobile-width-align pr-0"
                  >
                    <div>
                      <TableButtons
                        class="buttonMargin ml-1 mb-2 mb-md-0 mobile-width-fix"
                        :items="rows"
                        :json_fieldz="json_fields"
                        :name="`AllOrders - Page ${page}.xls`"
                        :bulkname="`AllOrders.xls`"
                        :fetch="fetchOrdersListNoPagination"
                        :table-fields="tableFields"
                        :table-data="tableData"
                        :title="`My Orders -${meta.from} to ${meta.to} of ${meta.total} entries`"
                        @refresh="onClickRefresh"
                      />
                    </div>
                  </b-col>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="6"
                class="d-flex flex-wrap justify-content-start align-items-center mb-1 mb-md-0"
              >
                <div
                  v-if="selection === 'confirmed' || selection === 'draft'"
                  class="font-weight-bolder text-nowrap"
                >
                  {{ `${selectedWaybillArray.length} record${selectedWaybillArray.length === 1 ? '' : 's'}` }} selected
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            variant="light"
            opacity="0.30"
            blur="10px"
            rounded="sm"
          >

            <b-table
              id="my-order-table"
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
              class="hide-vertical-scroll"
              @sort-changed="sortChanged"
            >

              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template
                v-if="(selection === 'confirmed' || selection === 'draft')"
                #head(idd)
              >
                <div class="d-flex align-items-start">
                  <div>
                    <b-form-checkbox
                      id="selectAllCheckbox"
                      v-model="selectAllCheckbox"
                      name="selectAllCheckbox"
                      @change="onClickSelectAllCheckbox()"
                    />
                  </div>
                </div>
              </template>
              <template
                v-if="(selection === 'confirmed' || selection === 'draft')"
                #cell(idd)="data"
              >
                <div class="d-flex align-items-start">
                  <div>
                    <div class="font-weight-bolder">
                      <b-form-checkbox
                        v-model="selectedWaybillArray"
                        :value="data.item.waybill_number"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(created_at)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.item.waybill_number)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                      @click="onClickViewOrder(data.item.id)"
                    >
                      {{ data.item.waybill_number }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ data.item.order_no }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ __dateTimeFormatter(data.value) }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(customer_email)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.customer_name }}
                    </div>
                    <div class="font-small-2">
                      {{ data.value }}
                    </div>
                    <div class="font-small-2">
                      <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                    </div>
                    <div
                      v-if="data.item.customer_secondary_phone !== null"
                      class="font-small-2"
                    >
                      <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(delivery_possibility)="data">
                <div
                  v-if="data.item.delivery_possibility"
                  :style="{ width:'150px'}"
                >
                  {{ data.item.delivery_possibility }}%
                  <b-progress
                    :value="data.item.delivery_possibility"
                    :variant="getProgressVariant(data.item.delivery_possibility)"
                    :class="getProgressBarClass(data.item.delivery_possibility)"
                    max="100"
                  />
                </div>
                <div
                  v-if="!data.item.delivery_possibility"
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>

                </div>
              </template>
              <template #cell(cod)="data">
                <div class="amount-col">
                  {{ displayAmountWithCurrency(data.value) }}
                </div>
              </template>

              <template #cell(weight)="data">
                <div class="pl-3">
                  {{ (data.value).toLocaleString() }}
                </div>
              </template>

              <template #cell(origin_warehouse)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      CITY - {{ data.item.origin_city.name }}
                    </div>
                    <div class="font-small-2">
                      Warehouse - {{ data.value.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(destination_warehouse)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      CITY - {{ data.item.destination_city.name }}
                      <!--                    </b-badge>-->
                    </div>
                    <div class="font-small-2">
                      Warehouse - {{ data.value.name || 'N/A' }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(delivery_charge)="data">
                <div class="amount-col">
                  {{ displayAmountWithCurrency(data.value) }}
                </div>
              </template>

              <template #cell(collected_cod)="data">
                <div class="amount-col">
                  {{ (displayAmountWithCurrency(data.value)) || '---' }}
                </div>
              </template>
              <template #cell(order_setting.is_pickup_enable)="data">
                <b-badge :variant="data.value ? 'light-success':'light-danger'">
                  {{ data.value ? 'Yes' : 'No' }}
                </b-badge>
              </template>

              <!--  Flag Componant -->
              <template #cell(order_flags)="data">
                <div class="d-flex align-items-center">
                  <FlagDisplay :flags="data.item.order_flags" />
                </div>
              </template>

              <template #cell(remark)="data">
                <b-badge
                  :variant="data.value > 0 ? 'light-info':'light-danger'"
                  @click="onClickMakeRemark(data.item.id)"
                >
                  {{ data.value === 0 ? 'None' : `${data.value} remark${data.value === 1 ? '' : 's'}` }}
                </b-badge>
              </template>

              <template #cell(status.key)="data">
                <order-status-badge
                  :status-key="data.value"
                />
              </template>

              <template #cell(action)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="onClickViewOrder(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    View Details
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="onClickMakeRemark(data.item.id)"
                  >
                    <feather-icon icon="BookmarkIcon" />
                    Make Remark
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="onClickMakeFlag(data.item.waybill_number, data.item.status.key, data.item.id)"
                  >
                    <feather-icon icon="FlagIcon" />
                    Add Flag
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.status.key === 'key_1'"
                    @click="onClickEditDraft(data.item, data.item.waybill_number)"
                  >
                    <feather-icon icon="EditIcon" />
                    Edit
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  md="3"
                >
                  <label class="width-75">Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block width-125"
                  />
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  md="9"
                >
                  <span class="text-muted  pagination-text mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>

              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
      <view-order-modal ref="viewOrderModal" />
      <make-remark ref="add_make_remark" />
      <make-future-request ref="add_future_request" />
      <make-flag ref="add_flag" />
      <bulk-pick-up ref="add_bulk_pickup" />
      <cancel-orders ref="add_bulk_cancel" />
      <bulk-action-modal
        ref="bulkActionModal"
        :form_structure="formStructure"
        :status_key="status_key"
        :selected_waybill_array="selectedWaybillArray"
      />
      <feedback-form />
    </div>
    <edit-draft-details ref="editDraftOrder" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  VBTooltip,
  // BAlert,
  BProgress,
} from 'bootstrap-vue'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import Filter from '@core/mixins/filter'
import ErrorMessage from '@core/mixins/ErrorMessage'
import displayAmountWithCurrency from '@core/mixins/currency'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import flatPickr from 'vue-flatpickr-component'
import ViewOrderModal from '@/components/orders/ViewOrderModal.vue'
import FlagDisplay from '@/components/orders/OrderFlags/FlagDisplay.vue'
import store from '@/store/index'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import SummaryCard from '@core/components/summary-card/SummaryCard.vue'
import MakeRemark from '@/components/orders/remarks/MakeRemark.vue'
import MakeFutureRequest from '@/components/orders/MakeFutureRequest.vue'
import MakeFlag from '@/components/orders/MakeFlag.vue'
import BulkPickUp from '@/components/orders/BulkPickUp.vue'
import CancelOrders from '@/components/orders/CancelOrders.vue'
import BulkActionModal from '@/components/status-flow/bulk-actions/BulkActionModal.vue'
import FeedbackForm from '@/components/Feedback/FeedbackForm.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import EditDraftDetails from '../components/merchant/EditDraftDetails.vue'

const OrderRepository = RepositoryFactory.get('order')
const ResourceRepository = RepositoryFactory.get('resource')
const MerchantRepository = RepositoryFactory.get('merchant')
const LayoutRepository = RepositoryFactory.get('layout')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BPagination,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BBadge,
    OrderStatusBadge,
    BAvatar,
    SummaryCard,
    ViewOrderModal,
    MakeRemark,
    MakeFutureRequest,
    MakeFlag,
    BulkPickUp,
    CancelOrders,
    BCardActions,
    BulkActionModal,
    FeedbackForm,
    EditDraftDetails,
    FilterButtons,
    // BAlert,
    BProgress,
    FlagDisplay,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage, displayAmountWithCurrency],
  data() {
    return {
      businesses: [],
      layoutDropdownOptions: {},
      avatarText,
      dataLoading: false,
      cityOptions: [],
      statusOptions: [],
      orderOptions: {
        all_orders: {},
        draft: {},
        confirmed: {},
        cancelled: {},
        delivered: {},
        partially_delivered: {},
        rescheduled: {},
        failed: {},
      },
      flagOptions: [],
      pickupSettingOptions: [
        { id: 'enabled', name: 'Enabled' },
        { id: 'disabled', name: 'Disabled' },
      ],
      filters: {},
      selectedStatusFilters: [],
      statusFilters: [],
      selectedFlagFilters: [],
      selectedPickupSettingFilters: [],
      selectedBusinessFilters: [],
      total: 0,
      meta: {},
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
      totalRows: 1,
      page: 1,
      fields: [
        { key: 'idd', label: '', sortable: true },
        { key: 'created_at', label: 'WAYBILL NUMBER', sortable: true },
        { key: 'customer_email', label: 'Customer', sortable: true },
        // {
        //   key: 'delivery_possibility',
        //   label: 'Delivery Probability',
        //   sortable: true,
        //   thClass: ' text-left',
        //   tdClass: ' text-center',
        // },
        {
          key: 'origin_warehouse',
          label: 'Origin',
          sortable: true,
        },
        {
          key: 'destination_warehouse',
          label: 'Destination',
          sortable: true,
        },
        {
          key: 'cod',
          label: 'COD',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'collected_cod',
          label: 'Collected Cod',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'weight',
          label: 'Weight(Kg)',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          key: 'delivery_charge',
          label: 'Delivery Charge',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'order_setting.is_pickup_enable', label: 'Pickup Enabled', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'order_flags', label: 'Flags', sortable: false },
        {
          key: 'remark', label: 'Remarks', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status.key',
          label: 'Status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      selectedWaybillArray: [],
      selectedStatusArray: [],
      json_fields: {
        'Waybill Number': 'waybill_number',
        'Merchant Business': {
          field: 'merchant_business.business_name',
          callback: value => `"${value}"`,
        },
        'Created Date': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Order Number': {
          field: 'order_no',
          callback: value => `"${value}"`,
        },
        'Origin City': {
          field: 'origin_city.name',
          callback: value => `"${value}"`,
        },
        'Origin Warehouse': {
          field: 'origin_warehouse.name',
          callback: value => `"${value}"`,
        },
        'Destination City': {
          field: 'destination_city.name',
          callback: value => `"${value}"`,
        },
        'Destination Warehouse': {
          field: 'destination_warehouse.name',
          callback: value => `"${value}"`,
        },
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        COD: {
          field: 'cod',
          callback: value => `"${value}"`,
        },
        'Delivery Charge': {
          field: 'delivery_charge',
          callback: value => `"${value}"`,
        },
        'Collected Cod': 'collected_cod',
        Weight: 'weight',
        Flag: {
          field: 'order_flags',
          callback: value => {
            const flagNames = value.map(flag => flag.name).join(', ')
            return `"${flagNames}"`
          },
        },
        Description: {
          field: 'description',
          callback: value => `"${value}"`,
        },
        Status: 'status.name',
      },
      orders: [],
      selection: 'allOrders',
      key: '',
      formStructure: [
        {
          type: 'input',
          placeholder: '',
          inputType: 'text',
          title: 'Waybill Number',
          value: 'waybill_number',
          class: 'col-3',
          validation: 'required',
        },
      ],
      format: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },
      tableFields: [
        'Waybill Number',
        'Created Date',
        'Order Number',
        'Origin City',
        'Origin Warehouse',
        'Destination City',
        'Destination Warehouse',
        'Customer Name',
        'Customer Email',
        'Customer Address',
        'Customer Phone',
        { title: 'COD', styles: { halign: 'right' } },
        { title: 'Delivery Charge', styles: { halign: 'right' } },
        { title: 'Collected Cod', styles: { halign: 'right' } },
        { title: 'Weight', styles: { halign: 'right' } },
        'Description',
        'Status',
      ],
      status_key: '',
      is_pickup_enable: true,
      actionClicked: false,
      selectAllCheckbox: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    tableData() {
      return this.rows.map(item => [item.waybill_number, item.created_at ? new Date(item.created_at).toLocaleDateString('en-GB', this.format) : '-', item.order_no, item.origin_city.name, item.origin_warehouse.name, item.destination_city.name, item.destination_warehouse.name, item.customer_name, item.customer_email, item.customer_address, [item.customer_phone, item.customer_secondary_phone], (item.cod).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), (item.delivery_charge).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), item.collected_cod ? (item.collected_cod).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-', item.weight, item.description, item.status.name])
    },
  },
  watch: {
    page() {
      this.fetchOrdersList()
    },
    perPage() {
      this.fetchOrdersList()
    },
    selectedStatusFilters: {
      async handler(val) {
        let keyList = ''
        val.forEach(key => {
          keyList += `${key},`
        })
        this.filters = {
          status: keyList,
        }
        this.$nextTick(() => {
          this.fetchOrdersList()
        })
      },
      deep: true,
    },
    statusFilters: {
      async handler(val) {
        let keyList = ''
        val.forEach(key => {
          keyList += `${key},`
        })
        this.filters = {
          status: keyList,
        }
      },
      deep: true,
    },
    selectedFlagFilters: {
      async handler(val) {
        let keyList = ''
        val.forEach(key => {
          keyList += `${key},`
        })
        this.filters = {
          order_flag: keyList,
        }
      },
      deep: true,
    },
    selectedBusinessFilters: {
      async handler(val) {
        let keyList = ''
        val.forEach(key => {
          keyList += `${key},`
        })
        this.filters = {
          ...this.filters,
          'merchantBusiness.id': keyList,
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getMerchantBusinessSettingsDetails()
    this.getCityList()
    this.getFlagList()
    this.statusOptions = JSON.parse(localStorage.getItem('order_statuses'))
    this.getOrderSummary()
    this.checkOrderCreateStatus()
    this.fetchOrdersList()
    this.getBusinessList()
    await this.getWaybillLayout()
  },
  methods: {
    async getWaybillLayout() {
      try {
        const { data } = (await LayoutRepository.getActiveWaybillLayout())
        this.layoutDropdownOptions = data.data
      } catch (e) {
        this.showErrorMessage('Couldn\'t fetch waybill layout!')
      }
    },
    getProgressVariant(value) {
      if (value >= 0.00 && value <= 30.00) return 'danger'
      if (value >= 30.01 && value <= 64.00) return 'warning'
      if (value >= 64.01 && value <= 100) return 'success'
      return ''
    },
    getProgressBarClass(value) {
      if (value >= 0.00 && value <= 30.00) return 'progress-bar-danager'
      if (value >= 30.01 && value <= 64.00) return 'progress-bar-warning'
      if (value >= 64.01 && value <= 100) return 'progress-bar-success'
      return ''
    },
    onClickViewOrder(id) {
      this.$refs.viewOrderModal.openModal(id)
    },
    onClickSelectAll() {
      this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
    },
    onClickDeselectAll() {
      this.selectedWaybillArray = []
    },
    onClickPrintDispatch(id) {
      this.$router.push({
        name: 'print-pod',
        params: {
          waybillId: id,
          selectedWaybills: this.selectedWaybillArray,
        },
      })
    },
    async fetchOrdersList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        if (this.selection === 'allOrders' || this.selection === '') {
          const { data } = (await OrderRepository.getOrderList(this.page, this.filterQuery, this.perPage))
          this.rows = data.data
          this.meta = data.meta
        } else {
          const { data } = (await OrderRepository.getOrderListbyKey(this.page, this.key, this.filterQuery, this.perPage))
          this.rows = data.data
          this.meta = data.meta
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchOrdersListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        if (this.selection === 'allOrders' || this.selection === '') {
          const { data } = (await OrderRepository.getOrderListNoPagination(this.filterQuery))
          return data.data
        /* eslint-disable no-else-return */
        } else {
          const { data } = (await OrderRepository.getOrderListbyKeyNoPagination(this.key, this.filterQuery))
          return data.data
        }
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
        return []
      }
    },
    async getBusinessList() {
      try {
        this.loading = true
        const { data } = (await ResourceRepository.getBusinessList()).data
        this.businesses = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getCityList() {
      this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityListDropdownList()).data.data
      if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
    },
    async getFlagList() {
      this.flagOptions = localStorage.getItem('flag_list') ? JSON.parse(localStorage.getItem('flag_list')) : (await ResourceRepository.getFlagList()).data.data
      if (!localStorage.getItem('flag_list')) localStorage.setItem('flag_list', JSON.stringify(this.flagOptions))
    },
    async getOrderSummary() {
      const { data } = (await OrderRepository.getOrderSummery()).data
      this.orderOptions = data
    },
    async getMerchantBusinessSettingsDetails() {
      try {
        const data = localStorage.getItem('merchant_business_setting') ? JSON.parse(localStorage.getItem('merchant_business_setting')) : (await MerchantRepository.getMerchantBusinessSettingsDetails()).data.data
        if (!localStorage.getItem('merchant_business_setting')) localStorage.setItem('merchant_business_setting', JSON.stringify(data))
        this.is_pickup_enable = data.is_pickup_enable
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickCreate() {
      this.$router.replace('/add-new-order')
    },
    // REQUIRED
    applyFilters() {
      this.fetchOrdersList()
      this.onClickDeselectAll()
    },
    clearFilters() {
      this.statusFilters = ''
      this.selectedFlagFilters = ''
      this.selectedBusinessFilters = ''
      this.filters = {}
      this.$nextTick(() => {
        this.fetchOrdersList()
      })
    },
    handleChangePage(page) {
      this.page = page
    },
    changePage(value) {
      this.perPage = value
    },
    onClickMakeRemark(id) {
      this.$refs.add_make_remark.openModal(id)
    },
    onClickEditDraft(id, waybillNumber) {
      this.$refs.editDraftOrder.openModal(id, waybillNumber)
    },
    onClickMakeFutureRequest(wayBill) {
      this.$refs.add_future_request.openModal(wayBill)
    },
    onClickMakeFlag(wayBill, statusKey, id) {
      this.$refs.add_flag.openModal(wayBill, statusKey, id)
    },
    onClickMakeBulkPickup() {
      this.$refs.add_bulk_pickup.openModal(this.selectedWaybillArray)
    },
    async onSummaryCardClick(key, selection, firstLoad) {
      if (!firstLoad) {
        this.$set(this.filters, 'status', '')
        this.selectedStatusFilters = []
      }
      this.selectedWaybillArray = []
      this.selection = selection
      this.key = key
      if (key === 'key_2') await this.getNonPickupRequestOrderCount()
    },
    async onClickConfirm() {
      try {
        const payload = {
          waybill_numbers: this.selectedWaybillArray,
        }
        payload.status_key = 'key_2'
        payload.other_details = {}

        await OrderRepository.updateOrderStatus(payload)
        await this.fetchOrdersList()
        this.showSuccessMessage('Order updated successfully')
        this.selectedWaybillArray = []
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickDiscard() {
      try {
        await OrderRepository.deleteOrder(this.selectedWaybillArray)
        await this.fetchOrdersList()
        this.selectedWaybillArray = []
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickCancel() {
      try {
        await this.$refs.add_bulk_cancel.openModal(this.selectedWaybillArray)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getNonPickupRequestOrderCount() {
      try {
        const { data } = (await OrderRepository.getNonPickupRequestOrderCount()).data
        if (data > 0) {
          this.$swal({
            title: 'Pickup Request Orders!',
            text: 'You have confirmed pickup request orders for which a pickup request has not yet been made',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickBulkAction(key) {
      this.status_key = key
      this.$refs.bulkActionModal.openModal()
      this.fetchOrdersList()
    },
    onWarningConfirm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, confirm it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.onClickConfirm()
        }
      })
    },
    onWarningDeletion() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.onClickDiscard()
        }
      })
    },
    checkOrderCreateStatus() {
      if (sessionStorage.getItem('newlyAddedWaybills')) {
        const isDraftDisabled = this.statusOptions.find(element => element.key === 'key_1')
        if (isDraftDisabled && isDraftDisabled.is_active === false) {
          // Bypass the Swal dialog and clear session storage
          sessionStorage.removeItem('newlyAddedWaybills')
          return
        }
        this.onSummaryCardClick('key_1', 'draft', true)
        this.$swal({
          title: 'New Orders Created',
          text: 'Do you want to confirm them ?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes, confirm them!',
          cancelButtonText: 'Later',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.selectedWaybillArray = sessionStorage.getItem('newlyAddedWaybills').split(',')
            this.onClickConfirm()
          }
          sessionStorage.removeItem('newlyAddedWaybills')
        })
      } else {
        this.onSummaryCardClick('', 'allOrders', true)
      }
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.fetchOrdersList()
      }
    },
    onClickRefresh() {
      this.fetchOrdersList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
      } else {
        this.selectedWaybillArray = []
      }
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../@core/scss/button-color.scss';
.dropdown .dropdown-menu {
  max-height: 135px;
  overflow-y: auto;
}
.addNew {
  margin-top: 6px;
}
.buttonMargin {
  margin-top: 13px;
}
.my-orders-alert .alert {
  line-height: 30px;
}
// .amount-col {
//   width: 90px;
// }
@media (min-width: 1200px) {
  // .b-table thead tr th:last-child {
  //   position: sticky;
  //   right: 0;
  //   z-index: 0;
  // }
  #my-order-table tbody tr td:last-child {
    background-color: #f8f8f8;
  }
  #my-order-table thead tr th:nth-last-child(2) {
    position: sticky;
    right: 0px;
    z-index: 0;
  }
  #my-order-table tbody tr td:nth-last-child(2) {
    position: sticky;
    right: 0px;
    background-color: #f8f8f8;
    z-index: 0;
  }
  .setHeight {
    min-height: 37px; /* Set your desired height */
  }
}
@media (max-width: 767px) {
  #my-order-table thead th {
    position: sticky;
    top: 0;
  }
}
</style>
