<template>
  <div class="d-flex align-items-center">
    <div
      v-for="flag in flags"
      :key="flag.id"
    >
      <b-row>
        <b-col v-if="flag.type === 'flag_1'">
          <b-avatar
            v-b-tooltip.hover.v-info
            title="PickUp Request"
            size="25"
            variant="light-info"
          >
            <feather-icon
              icon="TruckIcon"
              size="14"
              class="text-body align-middle"
            />
          </b-avatar>
        </b-col>
        <b-col v-if="flag.type === 'flag_2'">
          <b-avatar
            v-b-tooltip.hover.v-danger
            title="Urgent Request"
            size="25"
            variant="light-danger"
          >
            <feather-icon
              icon="AlertOctagonIcon"
              size="14"
              class="text-body align-middle"
            />
          </b-avatar>
        </b-col>
        <b-col v-if="flag.type === 'flag_3'">
          <b-avatar
            v-b-tooltip.hover.v-primary
            title="Exchange request"
            size="25"
            variant="light-primary"
          >
            <feather-icon
              icon="RepeatIcon"
              size="14"
              class="text-body align-middle"
            />
          </b-avatar>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="flag.type === 'flag_4'">
          <b-avatar
            v-b-tooltip.hover.v-dark
            title="Return request"
            size="25"
            variant="light-dark"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="14"
              class="text-body align-middle"
            />
          </b-avatar>
        </b-col>
        <b-col v-if="flag.type === 'flag_5'">
          <b-avatar
            v-b-tooltip.hover.v-success
            title="Future Delivery request"
            size="25"
            variant="light-success"
          >
            <feather-icon
              icon="ClockIcon"
              size="14"
              class="text-body align-middle"
            />
          </b-avatar>
        </b-col>
        <b-col v-if="flag.type === 'flag_6'">
          <b-avatar
            v-b-tooltip.hover.v-warning
            title="Fragile request"
            size="25"
            variant="light-warning"
          >
            <feather-icon
              icon="UmbrellaIcon"
              size="14"
              class="text-body align-middle"
            />
          </b-avatar>
        </b-col>
        <b-col v-if="flag.type === 'flag_7'">
          <b-avatar
            v-b-tooltip.hover.v-danger
            title="Temporary Warehouse"
            size="25"
            variant="light-danger"
          >
            <feather-icon
              icon="HomeIcon"
              size="14"
              class="text-body align-middle"
            />
          </b-avatar>
        </b-col>
        <b-col v-if="flag.type === 'flag_8'">
          <b-avatar
            v-b-tooltip.hover.v-info
            title="Redelivery"
            size="25"
            variant="light-info"
          >
            <feather-icon
              icon="RotateCwIcon"
              size="14"
              class="text-body align-middle"
            />
          </b-avatar>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  VBTooltip, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    FeatherIcon,
    BAvatar,
    BRow,
    BCol,
  },
  props: {
    flags: {
      type: Array,
      required: true,
      validator(value) {
        return Array.isArray(value)
      },
    },
  },
}
</script>

<style scoped>
/* Add any styles if necessary */
</style>
