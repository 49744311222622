<template>
  <b-modal
    id="modal-cacel-order"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="CANCEL ORDERS"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-row>
        <b-col
          md="12"
        >
          <SetCancelled :waybill="wayBill" />
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCol,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import SetCancelled from '@/components/orders/SetCancelled.vue'

function initialState() {
  return {
    open: false,
    modalLoading: false,
    loading: false,
    wayBill: null,
    selectedFlag: null,
    action: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    SetCancelled,
    BModal,
    BCol,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(wayBill) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.wayBill = wayBill
    },
  },
}
</script>
<style lang="scss">
</style>
