<template>
  <b-overlay
    :show="modalLoading"
    blur="10px"
    opacity="0.30"
    rounded="sm"
    variant="light"
  >
    <ValidationObserver
      ref="updateMerchantForm"
      v-slot="{ handleSubmit }"
      slim
    >
      <b-form
        class="my-8"
        @submit.prevent="handleSubmit(onClickMakeExchangeRequest)"
      >
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Exchange Waybill"
              label-for="h-name"
              label-cols-lg="3"
            >
              <v-select
                v-model="form.exchange_waybill_number"
                :options="orderList"
                :reduce="option => option.waybill_number"
                label="waybill_number"
                placeholder="Select the Exchange Waybill"
                @search="(search, loading) => {
                  loading(true)
                  getOrderList(`filter[waybill_number]=${search}`).then(() => loading(false))
                }"
              >
                <template v-slot:option="option">
                  {{ option.waybill_number }} - {{ option.customer_name }} - {{ option.status.name }}
                </template>
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    style="pointer-events:none"
                    :required="!form.exchange_waybill_number"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Remark"
              label-for="h-name"
              label-cols-lg="3"
            >
              <b-form-input
                id="name"
                v-model="form.remark"
                placeholder="Enter the Remark"
                type="text"
                :state="form.remark.length < remarkMaxChar"
                :maxlength="remarkMaxChar"
              />
              <small
                class="textarea-counter-value float-right"
                :class="form.remark.length >= remarkMaxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ form.remark.length }}</span> / {{ remarkMaxChar }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="d-flex justify-content-end"
          >
            <div class="ml-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="ml-1"
              >
                Clear
              </b-button>
            </div>
          </b-col>
        </b-row>
        <!-- submit and reset -->
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import vSelect from 'vue-select'

const FlagRepository = RepositoryFactory.get('flag')
const OrderRepository = RepositoryFactory.get('order')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    waybill: String,
    setOpen: Function,
    refresh: Function,
  },
  data() {
    return {
      waybillOptions: [],
      open: false,
      form: {
        remark: '',
      },
      modalLoading: false,
      loading: false,
      perPage: 5,
      pageOptions: [5, 15, 25],
      totalRows: 1,
      page: 1,
      meta: {},
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'user', label: 'User', sortable: true },
        { key: 'remark', label: 'Remark', sortable: false },
        { key: 'created_date', label: 'Remark Date', sortable: false },
        { key: 'added_by', label: 'Added By', sortable: false },
      ],
      config: {
        minDate: '',
      },
      orderList: [],
      remarkMaxChar: 125,
    }
  },
  mounted() {
    this.getTomorrowDate()
    this.getOrderList()
  },
  methods: {
    async getTomorrowDate() {
      const currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      const day = currentDate.getDate()
      const month = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()
      this.config.minDate = `${year}-${month}-${day}`
    },
    async getOrderList(filterQuery) {
      try {
        this.loading = true
        const { data } = (await OrderRepository.getOrderList(1, filterQuery, 10)).data
        data.forEach(order => {
          if (this.orderList.filter(item => item.waybill_number === order.waybill_number).length === 0) this.orderList.push(order)
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async onClickMakeExchangeRequest() {
      this.modalLoading = true
      try {
        const payload = {
          waybill_numbers: [this.waybill],
          id: '3',
          type: 'flag_3',
          data: {
            original_waybill_number: this.waybill,
            exchange_waybill_number: this.form.exchange_waybill_number,
            remark: this.form.remark,
          },
        }
        await FlagRepository.createFlag(payload)
        this.showSuccessMessage('Flag Made successfully')
        this.setOpen(false)
        this.refresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
