<template>
  <b-modal
    id="modal-future-request-add"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="ADD FUTURE REQUEST"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickMakeFutureRequest)"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Future Request Delivery Date"
                label-for="h-name"
                label-cols-md="2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Future Request Delivery Date"
                  rules="required"
                >
                  <div class="position-relative">
                    <flat-pickr
                      id="Future Request Delivery Date"
                      v-model="form.date"
                      class="form-control"
                      :config="config"
                    />
                    <div
                      v-if="form.date"
                      class="flatpickr-cancel"
                      @click="clearDate()"
                    >
                      <feather-icon icon="XIcon" />
                    </div>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Remark"
                label-for="h-name"
                label-cols-md="2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Remark"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.remark"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the Remark"
                    type="text"
                    maxlength="125"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
// import vSelect from 'vue-select'

const FlagRepository = RepositoryFactory.get('flag')

function initialState() {
  return {
    open: false,
    wayBill: null,
    form: {},
    modalLoading: false,
    loading: false,
    perPage: 5,
    pageOptions: [5, 15, 25],
    totalRows: 1,
    page: 1,
    meta: {},
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    fields: [
      { key: 'user', label: 'User', sortable: true },
      { key: 'remark', label: 'Remark', sortable: false },
      { key: 'created_date', label: 'Remark Date', sortable: false },
      { key: 'added_by', label: 'Added By', sortable: false },
    ],
    config: {
      minDate: '',
    },
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    flatPickr,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(wayBill) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.wayBill = wayBill
      this.modalLoading = true
      await this.getTomorrowDate()
      // await this.fetchMerchantDetails()
      this.modalLoading = false
    },
    async getTomorrowDate() {
      const currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      const day = currentDate.getDate()
      const month = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()
      this.config.minDate = `${year}-${month}-${day}`
    },
    //
    async onClickMakeFutureRequest() {
      this.modalLoading = true
      try {
        const payload = {
          waybill_numbers: [this.wayBill],
          id: '5',
          type: 'flag_5',
          data: {
            future_delivery_date: this.form.date,
            remark: this.form.remark,
          },
        }
        await FlagRepository.createFlag(payload)
        this.showSuccessMessage('Future Request Made successfully')
        this.$parent.onClickRefresh()
      } catch (e) {
        this.showErrorMessage(e)
      }
      this.modalLoading = false
    },
    clearDate() {
      this.form.date = ''
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
