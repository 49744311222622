<template>
  <b-modal
    id="reject-action-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="MERCHANT FEEDBACK"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <b-col
            md="12"
            class="my-1 text-center"
          >
            <h5>Please take a moment in filling out this feedback form and let us know what you think of our service</h5>
          </b-col>
        </b-row>

        <b-row class="m-1">
          <b-col
            class="justify-content-center mb-1"
          >
            <b-form-group
              label="Delivery Service Feedback"
              label-for="vi-message"
            >
              <b-form-textarea
                id="textarea-state"
                v-model="form.message"
                :state="form.message.length < maxChar"
                class="char-textarea"
                :class="form.message.length >= maxChar ? 'text-danger' : ''"
                :maxlength="maxChar"
                placeholder="Enter Delivery Service Feedback"
                rows="3"
              />
              <small
                class="textarea-counter-value float-right"
                :class="form.message.length >= maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ form.message.length }}</span> / {{ maxChar }}
              </small>
            </b-form-group>
            <b-form-group
              label-for="vi-message2"
              class="d-flex justify-content-center"
            >
              <star-rating
                v-model="form.rating"
                :show-rating="false"
                :star-size="35"
                :animate="true"
                :glow="1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <!-- ss -->
          <b-col>
            <b-form-group
              label-for="vi-message"
              label="Software Feedback"
            >
              <b-form-textarea
                id="textarea-state"
                v-model="form.software_feedback"
                class="char-textarea"
                :class="form.software_feedback.length >= maxChar ? 'text-danger' : ''"
                :maxlength="maxChar"
                :state="form.software_feedback.length < maxChar"
                placeholder="Enter Software Feedback"
                rows="3"
              />
              <small
                class="textarea-counter-value float-right"
                :class="form.software_feedback.length >= maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ form.software_feedback.length }}</span> / {{ maxChar }}
              </small>
            </b-form-group>
            <b-form-group
              label-for="vi-message2"
              class="d-flex justify-content-center"
            >
              <star-rating
                v-model="form.software_rating"
                :show-rating="false"
                :star-size="35"
                :animate="true"
                :glow="2"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="m-1">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            type="submit"
            class="w-100 mb-1"
            :disabled="submitButtonDisabled"
          >
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Submit</span>
          </b-button>
        </b-row>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup, BModal, BFormTextarea, BButton, VBModal, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import StarRating from 'vue-star-rating'
import { appEnv } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FeedbackRepository = RepositoryFactory.get('feedback')

function initialState() {
  return {
    open: false,
    form: {
      message: '',
      rating: null,
      software_feedback: '',
      software_rating: null,
    },
    depositSelected: [],
    financeType: '',
    modalLoading: false,
    value: '',
    maxChar: 250,
  }
}

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormTextarea,
    BButton,
    BOverlay,
    StarRating,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    submitButtonDisabled() {
      return this.form.message.length === 0 && this.form.software_feedback.length === 0
    },
  },
  async mounted() {
    await this.getFeedbackSetting()
    this.askFeedback()
  },
  methods: {
    async getFeedbackSetting() {
      try {
        const { data } = (await FeedbackRepository.getFeedbackSetting()).data
        this.feedbackSetting = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    askFeedback() {
      let today
      if (appEnv === 'staging') today = new Date().getMinutes()
      else today = new Date().getDate()
      if (today % this.feedbackSetting.feedback_period === 0) {
        if (localStorage.getItem('feedback_asked') !== 'true') {
          this.open = true
          localStorage.setItem('feedback_asked', 'true')
        }
      } else {
        localStorage.setItem('feedback_asked', 'false')
      }
    },
    async handleSubmit() {
      try {
        this.modalLoading = true
        const payload = {
          message: this.form.message,
          rating: this.form.rating,
          software_feedback: this.form.software_feedback,
          software_rating: this.form.software_rating,
        }
        const res = await FeedbackRepository.createFeedback(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Feedback recorded successfully')
          this.open = false
        } else {
          this.showErrorMessage('Failed to record feedback')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
