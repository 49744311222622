var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.modalLoading,"blur":"10px","opacity":"0.30","rounded":"sm","variant":"light"}},[_c('ValidationObserver',{ref:"updateMerchantForm",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"my-8",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onClickMakeExchangeRequest)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Exchange Waybill","label-for":"h-name","label-cols-lg":"3"}},[_c('v-select',{attrs:{"options":_vm.orderList,"reduce":function (option) { return option.waybill_number; },"label":"waybill_number","placeholder":"Select the Exchange Waybill"},on:{"search":function (search, loading) {
                loading(true)
                _vm.getOrderList(("filter[waybill_number]=" + search)).then(function () { return loading(false); })
              }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.waybill_number)+" - "+_vm._s(option.customer_name)+" - "+_vm._s(option.status.name)+" ")]}},{key:"search",fn:function(ref){
              var attributes = ref.attributes;
              var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",staticStyle:{"pointer-events":"none"},attrs:{"required":!_vm.form.exchange_waybill_number}},'input',attributes,false),events))]}}],null,true),model:{value:(_vm.form.exchange_waybill_number),callback:function ($$v) {_vm.$set(_vm.form, "exchange_waybill_number", $$v)},expression:"form.exchange_waybill_number"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Remark","label-for":"h-name","label-cols-lg":"3"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":"Enter the Remark","type":"text","state":_vm.form.remark.length < _vm.remarkMaxChar,"maxlength":_vm.remarkMaxChar},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.form.remark.length >= _vm.remarkMaxChar ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.form.remark.length))]),_vm._v(" / "+_vm._s(_vm.remarkMaxChar)+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"ml-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Clear ")])],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }