<template>
  <b-overlay
    :show="modalLoading"
    blur="10px"
    opacity="0.30"
    rounded="sm"
    variant="light"
  >
    <ValidationObserver
      ref="updateMerchantForm"
      v-slot="{ handleSubmit }"
      slim
    >
      <b-form
        class="my-8"
        @submit.prevent="handleSubmit(onClickMakePickUpRequest)"
      >
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="PickUp Request Date"
              label-for="h-name"
              label-cols-lg="3"
            >
              <ValidationProvider
                #default="{ errors }"
                name="PickUp Request Date"
                rules="required"
              >
                <div class="position-relative">
                  <flat-pickr
                    id="PickUp Request Date"
                    v-model="form.date"
                    class="form-control"
                    :config="config"
                  />
                  <div
                    v-if="form.date"
                    class="flatpickr-cancel"
                    @click="clearDate()"
                  >
                    <feather-icon icon="XIcon" />
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Remark"
              label-for="h-name"
              label-cols-lg="3"
            >
              <b-form-input
                id="name"
                v-model="form.remark"
                placeholder="Enter the Remark"
                type="text"
                :state="form.remark.length < remarkMaxChar"
                :maxlength="remarkMaxChar"
              />
              <small
                class="textarea-counter-value float-right"
                :class="form.remark.length >= remarkMaxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ form.remark.length }}</span> / {{ remarkMaxChar }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit and reset -->
        <b-row>
          <b-col
            class="d-flex justify-content-end"
          >
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Clear
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FlagRepository = RepositoryFactory.get('flag')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    flatPickr,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    waybill: String,
    setOpen: Function,
    refresh: Function,
  },
  data() {
    return {
      open: false,
      form: {
        remark: '',
      },
      modalLoading: false,
      loading: false,
      perPage: 5,
      pageOptions: [5, 15, 25],
      totalRows: 1,
      page: 1,
      meta: {},
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'user', label: 'User', sortable: true },
        { key: 'remark', label: 'Remark', sortable: false },
        { key: 'created_date', label: 'Remark Date', sortable: false },
        { key: 'added_by', label: 'Added By', sortable: false },
      ],
      config: {
        minDate: '',
      },
      remarkMaxChar: 125,
    }
  },
  mounted() {
    this.getTomorrowDate()
  },
  methods: {
    async getTomorrowDate() {
      const currentDate = new Date(new Date().getTime())
      const day = currentDate.getDate()
      const month = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()
      this.config.minDate = `${year}-${month}-${day}`
    },
    //
    async onClickMakePickUpRequest() {
      this.modalLoading = true
      try {
        const payload = {
          waybill_numbers: Array.isArray(this.waybill) ? this.waybill : [this.waybill],
          id: '1',
          type: 'flag_1',
          data: {
            pickup_at: this.form.date,
            remark: this.form.remark,
          },
        }
        await FlagRepository.createFlag(payload)
        this.showSuccessMessage('Flag Made successfully')
        this.setOpen(false)
        this.refresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    clearDate() {
      this.form.date = ''
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
