<template>
  <b-overlay
    :show="modalLoading"
    blur="10px"
    opacity="0.30"
    rounded="sm"
    variant="light"
  >
    <ValidationObserver
      ref="updateMerchantForm"
      v-slot="{ handleSubmit }"
      slim
    >
      <b-form
        class="my-8"
        @submit.prevent="handleSubmit(onWarningConfirm)"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Reason"
              label-for="h-name"
              label-cols-md="2"
            >
              <ValidationProvider
                #default="{ errors }"
                name="flag"
                rules="required"
              >
                <v-select
                  v-model="selectedReason"
                  :options="reasonOptions"
                  :reduce="option => option.id"
                  label="name"
                  placeholder="Select Status"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit and reset -->
        <b-row>
          <b-col
            class="d-flex justify-content-end"
          >
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Clear
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BForm,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import vSelect from 'vue-select'

const OrderRepository = RepositoryFactory.get('order')
const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    'v-select': vSelect,
    BButton,
    BCol,
    BFormGroup,
    BForm,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    waybill: String,
  },
  data() {
    return {
      selectedReason: '',
      reasonOptions: [],
      open: false,
      form: {},
      modalLoading: false,
      loading: false,
      perPage: 5,
      pageOptions: [5, 15, 25],
      totalRows: 1,
      page: 1,
      meta: {},
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'user', label: 'User', sortable: true },
        { key: 'remark', label: 'Remark', sortable: false },
        { key: 'created_date', label: 'Remark Date', sortable: false },
        { key: 'added_by', label: 'Added By', sortable: false },
      ],
      config: {
        minDate: '',
      },
    }
  },
  mounted() {
    this.getReasonList()
  },
  methods: {
    async getReasonList() {
      const { data } = (await ResourceRepository.getReasonListForDropDown('key_3')).data
      this.reasonOptions = data
    },

    onWarningConfirm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.onClickSetCancelled()
        }
      })
    },

    async onClickSetCancelled() {
      this.modalLoading = true
      try {
        const payload = {
          waybill_numbers: Array.isArray(this.waybill) ? this.waybill : [this.waybill],
        }
        payload.status_key = 'key_3'
        payload.other_details = {
          failed_reason: this.selectedReason,
        }

        await OrderRepository.updateOrderStatus(payload)
        this.$parent.onClickRefresh()
        this.showSuccessMessage('Order updated successfully')
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
</style>
